.rojo {
  background: #F44336;
  color: #ffffff;
}
.verde {
  background-color: #205723;
  color: #ffffff;
}
.swal2-container {
  z-index: 9000 !important;
}
.swal2-title {
  font-family: system-ui;
}